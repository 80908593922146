import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-nav-user',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.nav.user/view.scss */
.navbar {
  border-bottom: 1px solid var(--wc-border);
  background: #fff;
}
.navbar .container {
  justify-content: initial;
  padding: 0;
}
.navbar .navbar-brand img {
  height: 36px;
}
.navbar .navbar-toggler {
  color: var(--wc-blue);
  margin-right: 12px;
}
.navbar .nav-link {
  color: var(--wc-text);
}
@media (min-width: 769px) {
  .navbar .menu-container {
    width: 100%;
  }
  .navbar .menu-container .wiz-top-menu {
    display: flex;
    justify-content: end;
  }
  .navbar .menu-container .wiz-top-menu .wiz-menu-item {
    min-width: 64px;
    padding-left: 8px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 14px;
  }
  .navbar .menu-container .wiz-top-menu .wiz-menu-item-hub {
    min-width: 64px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 14px;
  }
  .navbar .menu-container .wiz-top-menu .wiz-menu-item-hub a.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 30px;
    padding: 0;
  }
  .navbar .menu-container .wiz-top-menu .wiz-menu-item-hub a.btn img {
    width: 28px;
    height: 28px;
    object-fit: cover;
  }
  .navbar .menu-container .wiz-main-menu {
    display: flex;
  }
  .navbar .menu-container .wiz-main-menu .nav-item {
    position: relative;
    z-index: 1000;
    min-width: 130px;
    font-size: 18px;
  }
  .navbar .menu-container .wiz-main-menu .nav-item .nav-link {
    font-weight: 900;
    font-family: "main-eb";
  }
  .navbar .menu-container .wiz-main-menu .nav-item .active {
    color: var(--wc-blue);
  }
  .navbar .menu-container .wiz-main-menu .nav-item .sub-menu {
    display: none;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    padding-left: 12px;
    padding-right: 12px;
    background-color: rgba(251, 251, 251, 0.95);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  }
  .navbar .menu-container .wiz-main-menu .nav-item .sub-menu .nav-item {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar .menu-container .wiz-main-menu .nav-item:hover .sub-menu {
    display: block;
  }
  .navbar .menu-container .wiz-main-menu .nav-item:hover .sub-menu a.nav-item {
    height: 44px;
    font-family: main-eb;
  }
  .navbar .menu-container .wiz-main-menu .nav-item:hover .sub-menu a.nav-item:hover {
    color: #1156aa;
  }
}
.navbar a {
  color: var(--wc-text);
  cursor: pointer;
}
@media (max-width: 768px) {
  .navbar .navbar-brand img {
    height: 32px;
  }
  .navbar .wiz-top-menu {
    padding-top: 0;
  }
}`],
})
export class ComponentNavUserComponent implements OnInit {
    public data: any = {
        account_name: '',
        password: '',
    };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async login() {
        // Keycloak 로그인 호출
        const { code, data } = await wiz.call("login");
        if (code === 200) {
            // Keycloak 로그인 URL로 리다이렉트
            window.location.href = data;
        } else {
            await this.service.error("로그인에 실패했습니다.");
        }
    }

    public async logout() {
        const { code, data } = await wiz.call("logout");
        if (code === 200) {
            window.location.href = data;
        } else {
            await this.service.error("로그아웃에 실패했습니다.");
        }
    }
}

export default ComponentNavUserComponent;