import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.widget.workflow.node');
import { OnInit, ElementRef, ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'wiz-portal-dizest-widget-workflow-node',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.dizest.widget.workflow.node/view.scss */
.dizest-app-node {
  border-radius: 16px !important;
  background: #fff;
  width: 260px;
  transition: box-shadow 0.3s ease;
}
.dizest-app-node.node-selected {
  -webkit-box-shadow: 0 2px 20px 2px var(--wc-border);
}
.dizest-app-node.node-selected .card-header {
  background: var(--wc-blue);
}
.dizest-app-node.node-transparent {
  opacity: 0.6;
}
.dizest-app-node.node-error .card-header {
  background: var(--wc-red);
}
.dizest-app-node.node-success .card-header {
  background: #07cc35;
}
.dizest-app-node > *:first-child {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
.dizest-app-node > *:last-child {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.dizest-app-node .variable-header .input-header {
  cursor: pointer;
  margin-bottom: 0;
  border-radius: 0;
  top: 0;
  left: 0;
}
.dizest-app-node .variable-header .input-header:hover {
  background-color: antiquewhite;
}
.dizest-app-node .variable-header .output-header {
  cursor: pointer;
  margin-bottom: 0;
  border-radius: 0;
  top: auto;
  right: auto;
}
.dizest-app-node .variable-header .output-header:hover {
  background-color: antiquewhite;
}
.dizest-app-node textarea,
.dizest-app-node select {
  border: none;
}
.dizest-app-node textarea:hover, .dizest-app-node textarea:focus,
.dizest-app-node select:hover,
.dizest-app-node select:focus {
  border: none;
  box-shadow: none;
}
.dizest-app-node .debug-message {
  color: var(--tblr-code-color);
  font-size: 12px;
  border: none;
  background: #fff;
  padding: 6px;
  max-height: 200px;
  overflow: auto;
  cursor: auto;
  white-space: nowrap;
  border-radius: 0;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  margin: 0;
  line-height: 16px;
  font-family: "main-r";
}
.dizest-app-node .debug-message.hide {
  display: none;
}
.dizest-app-node .debug-message::-webkit-scrollbar-track {
  background-color: transparent;
}
.dizest-app-node .debug-message::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: transparent;
}
.dizest-app-node .debug-message::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: var(--wc-blue-light-3);
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}
.dizest-app-node .card-header {
  background: var(--wc-blue);
  color: #fff;
  padding: 6px 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.dizest-app-node .card-header.bg-blue-lt {
  color: var(--wc-blue) !important;
}
.dizest-app-node .card-header.bg-blue-lt .text-white {
  color: var(--wc-blue) !important;
}
.dizest-app-node .card-header .card-title {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dizest-app-node .card-header .card-title .version {
  font-size: 10px;
  font-weight: 100;
  font-family: "main-r";
}
.dizest-app-node .card-header .btn.btn-sm {
  color: var(--wc-blue);
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 32px;
}
.dizest-app-node .card-header.inactive {
  background: #fff;
}
.dizest-app-node .card-header.inactive .card-title {
  color: var(--wc-blue);
}
.dizest-app-node .card-header .avatar-area .avatar-icon {
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: var(--wc-background);
}
.dizest-app-node .avatar {
  border-radius: 12px;
}
.dizest-app-node .actions .status-indicator {
  width: 50px;
  height: 32px;
  border-right: 1px solid var(--wc-border);
}
.dizest-app-node .actions .status-indicator .status-indicator-circle {
  --tblr-status-circle-size: 8px;
}
.dizest-app-node .actions .status-indicator.finish-indicator {
  display: block !important;
  padding-top: 9px;
  text-align: center;
  font-size: 12px;
  background: rgba(47, 179, 68, 0.1);
  color: #2fb344;
  font-weight: 1000;
  cursor: pointer;
}
.dizest-app-node .actions .status-indicator.pending-indicator {
  display: block !important;
}
.dizest-app-node .actions .action-btn {
  flex: auto;
  text-align: center;
  padding: 6px 8px;
  border-right: 1px solid var(--wc-border);
  background: var(--wc-blue-light);
  color: var(--wc-blue);
  cursor: pointer;
}
.dizest-app-node .actions .action-btn i {
  width: 20px;
  text-align: center;
}
.dizest-app-node .actions .action-btn:hover {
  background: var(--wc-blue);
  color: #fff;
}
.dizest-app-node .actions .action-btn:last-child {
  border-right: none;
}
.dizest-app-node .variable-header .btn,
.dizest-app-node .value-header .btn {
  height: 12px;
  font-size: 8px;
  padding: 0 4px;
  line-height: 1;
  float: right;
  display: none;
}
.dizest-app-node .variable-header:hover .btn,
.dizest-app-node .value-header:hover .btn {
  display: inline-block;
}
.dizest-app-node .variable-header {
  display: flex;
  width: 100%;
  cursor: auto;
  line-height: 1;
}
.dizest-app-node .variable-header > div {
  width: 50%;
  text-align: center;
  background-color: var(--wc-light);
  padding: 4px 2px;
  font-weight: 1000;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}
.dizest-app-node .variable-header > div:first-child {
  border-right: 1px solid var(--wc-border);
}
.dizest-app-node .value-header {
  font-size: 12px;
  font-weight: 1000;
  padding: 2px;
  background-color: var(--wc-light);
  border-bottom: 1px solid var(--wc-border);
  text-align: center;
  line-height: 1;
}
.dizest-app-node .variable-container {
  width: 100% !important;
  display: flex;
  cursor: auto;
}
.dizest-app-node .variable-container .input-container,
.dizest-app-node .variable-container .output-container {
  width: 50%;
  background: repeating-linear-gradient(-45deg, var(--wc-light), var(--wc-light) 2px, #fff 0, #fff 6px);
}
.dizest-app-node .variable-container .input-container > div,
.dizest-app-node .variable-container .output-container > div {
  border-bottom: 1px solid var(--wc-border);
}
.dizest-app-node .variable-container .input-container .value-wrapper,
.dizest-app-node .variable-container .output-container .value-wrapper {
  display: flex;
}
.dizest-app-node .variable-container .input-container .input-text,
.dizest-app-node .variable-container .input-container .output-text,
.dizest-app-node .variable-container .output-container .input-text,
.dizest-app-node .variable-container .output-container .output-text {
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  height: 25px;
  background: #fff;
}
.dizest-app-node .variable-container .input-container .input-text.disabled,
.dizest-app-node .variable-container .input-container .output-text.disabled,
.dizest-app-node .variable-container .output-container .input-text.disabled,
.dizest-app-node .variable-container .output-container .output-text.disabled {
  background: transparent;
}
.dizest-app-node .variable-container .input-container .input-text,
.dizest-app-node .variable-container .output-container .input-text {
  padding: 4px;
  padding-left: 10px;
  padding-right: 5px;
}
.dizest-app-node .variable-container .input-container .output-text,
.dizest-app-node .variable-container .output-container .output-text {
  text-align: right;
  padding: 4px;
  padding-left: 5px;
  padding-right: 10px;
}
.dizest-app-node .variable-container .input-container .inputs,
.dizest-app-node .variable-container .input-container .outputs,
.dizest-app-node .variable-container .output-container .inputs,
.dizest-app-node .variable-container .output-container .outputs {
  width: 0px;
}
.dizest-app-node .variable-container .input-container .inputs .input,
.dizest-app-node .variable-container .input-container .inputs .output,
.dizest-app-node .variable-container .input-container .outputs .input,
.dizest-app-node .variable-container .input-container .outputs .output,
.dizest-app-node .variable-container .output-container .inputs .input,
.dizest-app-node .variable-container .output-container .inputs .output,
.dizest-app-node .variable-container .output-container .outputs .input,
.dizest-app-node .variable-container .output-container .outputs .output {
  top: 5px;
  margin-bottom: 10px;
  height: 15px;
  width: 15px;
  border: 2px solid var(--wc-light);
  cursor: pointer;
  background: white;
}
.dizest-app-node .variable-container .input-container .inputs .input:hover,
.dizest-app-node .variable-container .input-container .inputs .output:hover,
.dizest-app-node .variable-container .input-container .outputs .input:hover,
.dizest-app-node .variable-container .input-container .outputs .output:hover,
.dizest-app-node .variable-container .output-container .inputs .input:hover,
.dizest-app-node .variable-container .output-container .inputs .output:hover,
.dizest-app-node .variable-container .output-container .outputs .input:hover,
.dizest-app-node .variable-container .output-container .outputs .output:hover {
  background: #4ea9ff;
}
.dizest-app-node .variable-container .input-container .inputs,
.dizest-app-node .variable-container .output-container .inputs {
  position: absolute;
  left: 18px;
}
.dizest-app-node .variable-container .input-container .outputs,
.dizest-app-node .variable-container .output-container .outputs {
  position: absolute;
  right: 10px;
}
.dizest-app-node .input-container {
  border-right: 1px solid var(--wc-border);
}
.dizest-app-node .value-container {
  cursor: auto;
}
.dizest-app-node .value-container .value-configure {
  background: var(--wc-blue);
  color: #fff;
}
.dizest-app-node .value-container .value-configure .row {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--wc-border);
}
.dizest-app-node .value-container .value-configure .row > * {
  padding: 0;
  margin: 0;
}
.dizest-app-node .value-container .value-configure .option-title {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "main-eb";
  font-size: 12px;
}
.dizest-app-node .value-container .value-configure textarea,
.dizest-app-node .value-container .value-configure select,
.dizest-app-node .value-container .value-configure input {
  width: 100%;
  height: auto;
  min-height: 24px;
}
.dizest-app-node .value-container .value-configure input {
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.dizest-app-node .value-container .value-configure input:hover {
  box-shadow: none;
}
.dizest-app-node .value-container .value-configure textarea {
  text-align: justify;
}
.dizest-app-node .value-container .value-configure .btn.btn-sm.btn-red {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 0;
}
.dizest-app-node .value-container .value-configure table {
  width: 100%;
}
.dizest-app-node .value-container .value-configure table tr {
  background: #fff;
  color: var(--wc-text);
}
.dizest-app-node .value-container .value-configure table tr td,
.dizest-app-node .value-container .value-configure table tr th {
  border: 1px solid var(--wc-border);
}
.dizest-app-node .value-container .value-configure table .btn {
  height: 20px;
  border: none;
  border-radius: 0;
}
.dizest-app-node .value-container .value-wrapper {
  display: flex;
  border-bottom: 1px solid var(--wc-border);
}
.dizest-app-node .value-container .value-wrapper .value-title {
  border-right: 1px solid var(--wc-border);
  padding: 4px 8px;
  width: 100px;
  font-size: 12px;
  word-break: break-all;
  flex: unset;
}
.dizest-app-node .value-container .value-wrapper:hover .value-title, .dizest-app-node .value-container .value-wrapper.hover .value-title {
  width: 80px;
}
.dizest-app-node .value-container .value-wrapper .value-data {
  flex: 1;
}
.dizest-app-node .value-container .value-wrapper .value-data textarea,
.dizest-app-node .value-container .value-wrapper .value-data select,
.dizest-app-node .value-container .value-wrapper .value-data input {
  width: 100%;
  height: auto;
  min-height: 24px;
}
.dizest-app-node .value-container .value-wrapper input {
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.dizest-app-node .value-container .value-wrapper input:hover {
  box-shadow: none;
}
.dizest-app-node .value-wrapper {
  display: flex;
}
.dizest-app-node .value-wrapper .value-action {
  display: none;
  width: 20px;
  padding: 0;
}
.dizest-app-node .value-wrapper .value-action .btn {
  width: 100%;
  height: 100%;
  padding: 0 2px;
  font-size: 12px;
  border-radius: 0px;
  border: none;
}
.dizest-app-node .value-wrapper:hover .value-action, .dizest-app-node .value-wrapper.hover .value-action {
  display: block;
}
.dizest-app-node .value-wrapper .value-title {
  flex: 1;
  padding: 4px 8px;
  font-size: 12px;
  word-break: break-all;
}
.dizest-app-node .value-wrapper .value-title input {
  font-size: 12px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  background: transparent;
  padding: 2px 0;
  outline: none;
  line-height: 1;
}
.dizest-app-node .value-add {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  width: 100%;
  height: 100%;
}
.dizest-app-node .value-add:hover {
  background: var(--wc-light);
}

.progress {
  border-radius: 0;
}

.hidden {
  display: none !important;
}

.description {
  max-height: 120px;
  overflow-y: auto;
}`],
})
export class PortalDizestWidgetWorkflowNodeComponent implements OnInit {
    public scope: any = null;
    public flow: any = null;
    public app: any = null;
    public phase: string;

    @ViewChild('debug')
    public debugElement: ElementRef;

    constructor() { }

    public ngOnInit() {
    }

    public isDragging: boolean = false;
    public mouseDownTime: number = 0;

    public onMouseDown(event: MouseEvent) {
        this.isDragging = false;
        this.mouseDownTime = new Date().getTime();
    }

    public onMouseUp(event: MouseEvent) {
        const mouseUpTime = new Date().getTime();
        const timeDiff = mouseUpTime - this.mouseDownTime;

        if (timeDiff < 400 && !this.isDragging) { // 200ms 이내의 클릭만 처리
            this.showInfo();
        }

        this.isDragging = false;
    }

    public onMouseMove(event: MouseEvent) {
        if (event.buttons === 1) { // 마우스 왼쪽 버튼이 눌린 상태
            this.isDragging = true;
        }
    }

    public actionState() {
        const phase = this.getPhase();
        if (phase === 'phase3') {
            return 'node-error';  // phase3이면 node-error 리턴
        }
        if (phase === 'phase2') {
            return 'node-success'
        }
        if (phase === 'phase1') {
            return 'node-selected'
        }

        return '';
    }



    // public actionState() {
    //     if (this.app.ctrl.hovered) {
    //         let ishovered = this.app.hovered();
    //         if (ishovered) {
    //             return 'node-selected';
    //         }
    //         return 'node-transparent';
    //     }
    //     if (!this.flow.ctrl.selected && this.flow.status() == 'error') {
    //         return 'node-error';
    //     }

    //     let isselected = this.flow.selected();
    //     if (isselected)
    //         return 'node-selected';

    //     if (this.flow.ctrl.selected)
    //         return 'node-transparent';
    //     return '';
    // }

    public showInfo() {
        this.scope.config.activeApp = false;
        this.scope.service.render();
        this.scope.config.wfinfo = false;
        this.scope.config.activeApp = this.app;
        this.scope.service.render();

    }

    public inputs() {
        let app = this.app;
        return app.inputs();
    }

    public outputs() {
        let app = this.app;
        return app.outputs();
    }

    public isActive() {
        return !this.flow.inactive();
    }

    public status() {
        return this.flow.status();
    }

    public log() {
        // this.getPhase();
        return this.flow.log();
    }

    public getPhase(): string {
        const log = this.flow.log();
        if (log.includes('Error: exit status')) {
            return 'phase3';
        } else if (log.includes('msg="sub-process exited"')) {
            return 'phase2';
        } else {
            return 'phase1';
        }
    }

    public isown() {
        return this.flow.ctrl.workflow.data.created_by === this.scope.service.auth.session.id;
    }

    //-------

    public async run() {
        this.flow.status('pending');
        await this.flow.logclear();
        await this.flow.run();
    }

    public async stop() {
        await this.flow.stop();
    }

    public async delete() {
        await this.flow.delete();
        this.scope.config.activeApp = false;
        this.scope.service.render();
    }

    public loadData() {
        this.startLoading();
        this.$timeout(() => {
            this.stopLoading();
        }, 3000);

    }


    // 로딩 시작 메서드
    public startLoading() {
        this.isLoading = true;
        if (this.scope && !this.scope.$$phase) {
            this.scope.$apply();
        }
    }

    // 로딩 종료 메서드
    public stopLoading() {
        this.isLoading = false;
        if (this.scope && !this.scope.$$phase) {
            this.scope.$apply();
        }
    }

}

export default PortalDizestWidgetWorkflowNodeComponent;