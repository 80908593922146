import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [``],
})
export class PageMainComponent implements OnInit {
    public info = {
        apps: 0,
        workflow: 0,
        service: 0,
    };
    public text = "";

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("count");
        this.info.apps = data.apps;
        this.info.workflows = data.workflows;
        this.info.services = data.services;
        this.info.datasets = data.datasets;
        await this.service.render();
    }

    public search() {
        let searchQuery = localStorage.getItem("searchquery");

        if (searchQuery) {
            searchQuery = JSON.parse(searchQuery);

            searchQuery.text = this.text;

            localStorage.setItem("searchquery", JSON.stringify(searchQuery));
        }
        this.service.app.router.navigate(['/search'], { queryParams: { text: this.text } });
    }

    public async openNotebook() {
        const { code, data } = this.service.api("notebook/login");
    }
}

export default PageMainComponent;