import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.search.filter');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-search-filter',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.search.filter/view.scss */
.accordion-body ul {
  padding-left: 1.9rem;
}
.accordion-body ul label.d-flex {
  cursor: pointer;
}
.accordion-body ul label.d-flex:hover input {
  border-color: #3598ff;
}
.accordion-body ul label.d-flex:hover input + div {
  background-color: #e6f4ff;
}
.accordion-body ul label.d-flex input:checked + div {
  background-color: #e6f4ff;
}

input.form-check-input {
  cursor: pointer;
}`],
})
export class PortalKrdcSearchFilterComponent implements OnInit {
    @Input() onSelect = (selectedFilters) => { };

    public selectedCategories = new Set<string>();
    public selectedGroups = new Set<string>();
    public selectedAccesses = new Set<string>();
    public selectedResourceTypes = new Set<string>();

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.init();
        this.initializeCheckboxStates();
        let cache = localStorage.getItem("searchfilter");
        let cacheTimestamp = localStorage.getItem("searchfilter:timestamp");
        if (!cacheTimestamp) cacheTimestamp = 0;
        cacheTimestamp = cacheTimestamp * 1;
        const isExpired = (cacheTimestamp + 60 * 10 * 1000) < +new Date();
        if (cache && !isExpired) {
            cache = JSON.parse(cache);
            if (cache.categories) cache.categories.split(",").forEach(it => this.selectedCategories.add(it))
            if (cache.groups) cache.groups.split(",").forEach(it => this.selectedGroups.add(it))
            if (cache.accesses) cache.accesses.split(",").forEach(it => this.selectedAccesses.add(it))
            if (cache.resourceTypes) cache.resourceTypes.split(",").forEach(it => this.selectedResourceTypes.add(it))
            this.onSelect(cache);
        }
        else {
            this.onSelect({ categories: "", groups: "", accesses: "", resourceTypes: "" });
        }
    }

    private initializeCheckboxStates() {
        this.menu.forEach(item => {
            item.children?.forEach(child => {
                child.children?.forEach(grandChild => {
                    this.updateSelection(this.selectedCategories, grandChild.id, false); // ID -> name으로 변경
                });
                this.updateSelection(this.selectedCategories, child.id, false); // ID -> name으로 변경
            });
            this.updateSelection(this.selectedCategories, item.id, false); // ID -> name으로 변경
        });

        // this.accesses.forEach(access => {
        //     this.updateSelection(this.selectedAccesses, access.value, false);
        // });

        this.updateParentCheckboxes();
    }

    public menu = [];
    public count = {};
    public groups = [
        { name: "전처리", value: "preprocessing" },
        { name: "모델", value: "model" },
        { name: "시각화", value: "visualization" },
        { name: "인프라", value: "infra" },
        { name: "데이터", value: "data" },
        { name: "분석", value: "analyze" },
        { name: "기타", value: "etc" },
    ];
    public accesses = [
        { name: "공개", value: "public" },
        { name: "승인 후 사용", value: "internal" },
    ];

    public async getCategory(categoryId) {
        const { code, data } = await this.service.api(`categories/${categoryId}`);
        if (code !== 200) return [];
        const subCategory = data;
        return subCategory
            .map(({ id, name }) => ({ id: id, name: name, count: 0, open: false }))
            .sort((a, b) => a.name - b.name);
    }

    public async getCount() {
        const { code, data } = await this.service.api(`statistics/categories/resources`);
        if (code !== 200) return;
        const entities = data;
        entities.forEach(({ category, cnt }) => {
            if (category.length < 4) {
                this.count[category] = cnt * 1;
                return;
            }

            const root = category.slice(0, 1);
            const middle = category.slice(0, 2);
            if (!this.count[root]) this.count[root] = 0;
            if (!this.count[middle]) this.count[middle] = 0;

            this.count[root] += cnt * 1;
            this.count[middle] += cnt * 1;
            this.count[category] = cnt * 1;
        });
    }

    public async init() {
        this.menu = await this.getCategory("ROOT");
        await this.getCount();
        await this.service.render();
        this.initializeCheckboxStates(); // 초기 상태로 설정
    }

    public async open(item) {
        if (!item.children) {
            item.children = await this.getCategory(item.id);
        }
        item.open = true;
        await this.service.render();
        this.updateParentCheckboxes();
    }

    public async close(item) {
        item.open = false;
        await this.service.render();
        this.updateParentCheckboxes();
    }

    public select(area, key, checked) {
        if (area === 'category') {
            // const categoryName = this.findCategoryNameById(this.menu, key); // ID -> name 매핑
            this.updateSelection(this.selectedCategories, key, checked);
            if (checked) {
                this.checkChildren(this.menu, key, true);
            } else {
                this.uncheckChildren(this.menu, key);
            }
            this.updateParentCheckboxes(); // 상위 카테고리의 상태를 업데이트
        } else if (area === 'group') {
            this.updateSelection(this.selectedGroups, key, checked);
        } else if (area === 'access') {
            const accessValue = this.findAccessValueByName(key); // Name -> Value 매핑
            this.updateSelection(this.selectedAccesses, accessValue, checked);
        } else if (area === 'resource_type') {
            this.updateSelection(this.selectedResourceTypes, key, checked);
        }
        const selectedFilters = {
            categories: this.formatSelectedFilter(Array.from(this.selectedCategories)),
            groups: this.formatSelectedFilter(Array.from(this.selectedGroups)),
            accesses: this.formatSelectedFilter(Array.from(this.selectedAccesses)),
            resourceTypes: this.formatSelectedFilter(Array.from(this.selectedResourceTypes)),
        };
        localStorage.setItem("searchfilter", JSON.stringify(selectedFilters));
        localStorage.setItem("searchfilter:timestamp", "" + +new Date());

        // 부모 컴포넌트로 전달
        this.onSelect(selectedFilters);
    }

    // Name -> Value를 찾는 헬퍼 함수 추가
    private findAccessValueByName(name: string): string | undefined {
        const access = this.accesses.find(a => a.name === name);
        return access ? access.value : undefined;
    }

    // 헬퍼 메서드: 필터 배열을 단일 값으로 반환할지 배열로 반환할지 결정
    private formatSelectedFilter(filterArray: string[]): string | string[] {
        return filterArray.length === 1 ? filterArray[0] : filterArray.join(",");
    }

    private checkChildren(menu: Category[], parentId: string, checked: boolean) {
        menu.forEach(item => {
            if (item.id === parentId) {
                item.children?.forEach(child => {
                    this.updateSelection(this.selectedCategories, child.id, checked); // ID -> name으로 변경
                    this.checkChildren([child], child.id, checked); // Recursively check children
                });
            } else {
                this.checkChildren(item.children || [], parentId, checked); // Recursively check children
            }
        });
    }

    private uncheckChildren(menu: Category[], parentId: string) {
        menu.forEach(item => {
            if (item.id === parentId) {
                item.children?.forEach(child => {
                    this.updateSelection(this.selectedCategories, child.id, false); // ID -> name으로 변경
                    this.uncheckChildren([child], child.id); // Recursively uncheck children
                });
            } else {
                this.uncheckChildren(item.children || [], parentId); // Recursively uncheck children
            }
        });
    }

    private updateParentCheckboxes(item) {
        if (!item || !item.children) return;

        const allChildrenChecked = item.children.every(child => this.selectedCategories.has(child.id)); // ID -> name으로 변경

        if (allChildrenChecked) {
            this.updateSelection(this.selectedCategories, item.id, true); // ID -> name으로 변경
        } else {
            this.updateSelection(this.selectedCategories, item.id, false); // ID -> name으로 변경
        }

        // 만약 item의 parent가 있다면, 부모 항목의 상태를 다시 업데이트
        if (item.parent) {
            this.updateParentCheckbox(item.parent);
        }
    }

    private updateParentCheckbox(item: Category) {
        const allChildrenChecked = item.children?.every(child => this.selectedCategories.has(child.id)) ?? false; // ID -> name으로 변경
        const someChildrenChecked = item.children?.some(child => this.selectedCategories.has(child.id)) ?? false; // ID -> name으로 변경

        if (allChildrenChecked) {
            this.updateSelection(this.selectedCategories, item.id, true); // ID -> name으로 변경
        } else if (someChildrenChecked) {
            this.updateSelection(this.selectedCategories, item.id, true); // ID -> name으로 변경
        } else {
            this.updateSelection(this.selectedCategories, item.id, false); // ID -> name으로 변경
        }

        if (item.parent) {
            this.updateParentCheckbox(item.parent);
        }
    }

    // 카테고리 ID로 이름을 찾는 헬퍼 함수 추가
    // private findCategoryNameById(menu: Category[], id: string): string | undefined {
    //     for (const item of menu) {
    //         if (item.id === id) {
    //             return item.name;
    //         }
    //         if (item.children) {
    //             const found = this.findCategoryNameById(item.children, id);
    //             if (found) {
    //                 return found;
    //             }
    //         }
    //     }
    //     return undefined;
    // }

    public resetFilters() {
        // 필터 상태 초기화
        this.selectedCategories.clear();
        this.selectedGroups.clear();
        this.selectedAccesses.clear();
        this.selectedResourceTypes.clear();

        // 상태 업데이트
        this.initializeCheckboxStates();


        // 필터 선택 변경 이벤트 호출
        const selectedFilters = {
            categories: this.formatSelectedFilter(Array.from(this.selectedCategories)),
            groups: this.formatSelectedFilter(Array.from(this.selectedGroups)),
            accesses: this.formatSelectedFilter(Array.from(this.selectedAccesses)),
            resourceTypes: this.formatSelectedFilter(Array.from(this.selectedResourceTypes)),
        };
        // 부모 컴포넌트로 필터 상태 전달
        this.onSelect(selectedFilters);
    }

    private updateSelection(set: Set<string>, key: string, checked: boolean) {
        if (checked) {
            set.add(key);
        } else {
            set.delete(key);
        }
    }
}

export default PortalKrdcSearchFilterComponent;